<template>
  <section>
    <b-card>
      <div class="mb-2 d-flex">
        <b-form-group class="mr-2">
          <b-form-radio-group
            v-model="chartSelectedRadio"
            button-variant="primary"
            :options="optionsRadio"
            buttons
            @change="changeRadio($event, 'DB')"
          />
        </b-form-group>

        <b-form-group>
          <flat-pickr
            v-model="chartParams.fromToDate"
            class="form-control d-inline-block"
            :config="configDateRangePickerChart"
            placeholder="Ngày bắt đầu và kết thúc"
            style="min-width: 220px;"
          />
        </b-form-group>
      </div>

      <div class="history-grid mb-3">
        <div class="history-grid__item">
          <h4>Tổng đơn hàng</h4>
          <h3 class="text-warning mb-0">
            {{ boxData.total_order }}
          </h3>
        </div>
        <div class="history-grid__item">
          <h4>Tổng doanh thu</h4>
          <h3 class="text-warning mb-0">
            {{ unitFormatOriginal(boxData.revenue) }}
          </h3>
        </div>
        <div class="history-grid__item">
          <h4>Lợi nhuận</h4>
          <h3 class="text-warning mb-0">
            {{ unitFormatOriginal(boxData.profit) }}
          </h3>
        </div>
        <div class="history-grid__item">
          <h4>Tổng CTV</h4>
          <h3 class="text-warning mb-0">
            {{ boxData.ctv }}
          </h3>
        </div>
      </div>

      <!-- CHARTS -->
      <b-row>
        <b-col
          md="7"
        >
          <line-chart-section
            title="Biểu đồ bán hàng"
            :chart-data="lineChartData"
          />
        </b-col>
        <b-col md="5">
          <h4 class="text-primary">
            Biểu đồ tổng số đơn hàng
          </h4>
          <doughnut-chartjs
            :chart-data="doughnutChartData"
          />
          <div class="mt-2">
            <h3>Tổng đơn hàng</h3>
            <h2 class="text-primary">
              {{ doughnutChartData.total_order }}
            </h2>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- <b-row class="row2">
      <b-col sm="6">
        <b-card>
          <line-chart-section
            title="Biểu đồ tổng số đơn hàng 1"
            :chart-data="lineChartData"
          />
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card>
          <line-chart-section
            title="Biểu đồ tổng vốn"
            :chart-data="lineChartData"
          />
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card>
          <line-chart-section
            title="Biểu đồ doanh thu"
            :chart-data="lineChartData"
          />
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card>
          <line-chart-section
            title="Biểu đồ lợi nhuận"
            :chart-data="lineChartData"
          />
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card>
          <line-chart-section
            title="Top seller trong nhóm"
            :chart-data="lineChartData"
          />
        </b-card>
      </b-col>
      <b-col sm="6">
        <b-card>
          <line-chart-section
            title="Sản phẩm bán chạy nhất tuần"
            :chart-data="lineChartData"
          />
        </b-card>
      </b-col>
    </b-row> -->
    <!-- BEST PRODUCTS -->
    <b-card>
      <h4 class="text-primary mb-2">
        Top sản phẩm đạt doanh thu cao nhất
      </h4>
      <div class="mb-2 d-flex">
        <b-form-group class="mr-2">
          <b-form-radio-group
            v-model="topProductSelectedRadio"
            button-variant="primary"
            :options="topProductOptionsRadio"
            buttons
            @change="changeRadio($event, 'TP')"
          />
        </b-form-group>

        <b-form-group>
          <flat-pickr
            v-model="topProductParams.fromToDate"
            class="form-control d-inline-block"
            :config="configDateRangePickerTP"
            placeholder="Ngày bắt đầu và kết thúc"
            style="min-width: 220px;"
          />
        </b-form-group>
      </div>
      <vue-good-table
        class="custom-good-table mt-2"
        style-class="vgt-table"
        :columns="bestProductFields"
        :rows="bestProductItems"
        :pagination-options="{
          enabled: true,
          perPage:referralPageLength
        }"
      >
        <!-- Table: Head -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>

        <!-- Table: Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Rank -->
          <span
            v-if="props.column.field === 'rank'"
            class="d-flex flex-wrap align-items-center"
          >
            <span
              class="d-block line-clamp"
              style="max-width: 320px;"
            >
              {{ props.row.originalIndex + 1 }}
            </span>
          </span>

          <!-- Column: Description -->
          <span
            v-else-if="props.column.field === 'name'"
            class="d-flex flex-wrap align-items-center"
          >
            <router-link
              class="d-block line-clamp"
              style="max-width: 320px;"
              :to="`/product/edit/${props.row.product_id_string}`"
            >
              {{ props.row.name }}
            </router-link>
          </span>

          <span
            v-else-if="props.column.field === 'category_name'"
            class="d-flex flex-wrap align-items-center"
          >
            <span
              class="d-block line-clamp"
              style="max-width: 320px;"
              :title="props.row.category_name"
            >
              {{ props.row.category_name }}
            </span>
          </span>

          <!-- Column: Description -->
          <span
            v-else-if="props.column.field === 'revenue'"
            class="align-items-center"
          >
            <span
              class="text-right"
              :title="props.row.revenue"
            >
              {{ unitFormatOriginal(props.row.revenue) }}
            </span>
          </span>

          <span
            v-else-if="props.column.field === 'commision'"
            class="align-items-center"
          >
            <span
              class="text-right"
              :title="props.row.commision"
            >
              {{ unitFormatOriginal(props.row.commision) }}
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap p-2">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="referralPageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="referralPageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({referralCurrentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import {
  BCard,
  BFormGroup,
  BFormRadioGroup,
  // BButton,
  BFormSelect,
  BPagination,
  BRow,
  BCol,
  // BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
// import MixinAffiliate from '../mixin'
import LineChartSection from './LineChartSection.vue'
import DoughnutChartjs from './DoughnutChartjs.vue'

export default {
  name: 'General',
  components: {
    flatPickr,
    VueGoodTable,
    BCard,
    BFormGroup,
    BFormRadioGroup,
    // BButton,
    BFormSelect,
    BPagination,
    BRow,
    BCol,
    LineChartSection,
    DoughnutChartjs,
  },
  // mixins: [MixinAffiliate],
  props: {},

  data() {
    return {
      selectedRadio: '24h',
      optionsRadio: [
        { text: '24h', value: 1 },
        { text: '7 Ngày', value: 7 },
        { text: '30 Ngày', value: 30 },
      ],
      rangeDate: null,
      isShowChart: false,
      lineChartData: {
        labels: [
          '1/12',
          '2/12',
          '3/12',
          '4/12',
          '5/12',
          '6/12',
          '7/12',
          '8/12',
          '9/12',
          '10/12',
          '11/12',
          '12/12',
        ],
        datasets: [
          {
            label: 'Tổng đơn hàng',
            backgroundColor: '#346AB2',
            data: [0, 150, 220, 290, 300, 350, 400, 450, 490, 550, 420, 600],
          },
          {
            label: 'Tổng doanh thu',
            backgroundColor: '#ECA124',
            data: [0, 15, 350, 465, 325, 500, 590, 545, 600, 645, 670, 700],
          },
          {
            label: 'Tổng lợi nhuận',
            backgroundColor: '#269780',
            data: [0, 390, 100, 400, 390, 460, 600, 625, 650, 699, 715, 750],
          },
          {
            label: 'Tổng chi phí',
            backgroundColor: '#F75E5E',
            data: [0, 450, 250, 320, 669, 710, 750, 800, 822, 830, 850, 890],
          },
        ],
      },
      doughnutChartData: {
        total_order: 0,
        labels: [
          'Mới',
          'Đơn chờ duyệt',
          'Đóng gói',
          'Chờ lấy hàng',
          'Vận chuyển',
          'Thành công',
          'Huỷ đơn',
          'Hoàn trả',
        ],
        datasets: [
          {
            backgroundColor: ['#90BE6D', '#2D9CDB', '#F9C74F', '#F94144', '#F3722C'],
            data: [90, 10, 18, 30, 50],
          },
        ],
      },
      filterSelected: 'all',
      filterTabs: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 'phan-thuong-gioi-thieu',
          label: 'Phần thưởng giới thiệu',
        },
        {
          id: 'chi-tiet',
          label: 'Chi tiết',
        },
        {
          id: 'trang-thai',
          label: 'Trạng thái',
        },
      ],
      referralFields: [
        {
          field: 'id', label: 'ID', sortable: false, thClass: 'text-left',
        },
        {
          field: 'reward', label: 'Phần thưởng', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'detail', label: 'Chi tiết', sortable: false, tdClass: 'text-left',
        },
        {
          field: 'status', label: 'Trạng thái', sortable: false, tdClass: 'text-center',
        },
      ],
      referralItems: [
        {
          id: '#01234',
          reward: '500.000₫',
          detail: 'Giới thiệu bạn mới',
          status: 0,
        },
        {
          id: '#01234',
          reward: '500.000₫',
          detail: 'Giới thiệu bạn mới',
          status: 1,
        },
      ],
      referralPageLength: 10,
      referralCurrentPage: 1,
      bestSellerFields: [
        {
          field: 'no', label: 'Rank', sortable: false, thClass: 'text-left',
        },
        {
          field: 'customer', label: 'Tên người bán', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'phone', label: 'Số điện thoại', sortable: false, thClass: 'text-left',
        },
        {
          field: 'total_quantity', label: 'Tổng đơn hàng', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'revenue', label: 'Doanh thu', sortable: false, tdClass: 'text-right', thClass: 'text-right',
        },
      ],
      bestSellerItems: [],
      bestProductFields: [
        {
          field: 'rank', label: 'Rank', sortable: false, thClass: 'text-left',
        },
        {
          field: 'name', label: 'Tên sản phẩm', sortable: false, thClass: 'text-left', tdClass: 'text-left',
        },
        {
          field: 'category_name', label: 'Danh mục', sortable: false, tdClass: 'text-left', thClass: 'text-left',
        },
        {
          field: 'quantity', label: 'Số lượng bán', sortable: false, tdClass: 'text-center',
        },
        {
          field: 'revenue', label: 'Doanh thu', sortable: false, tdClass: 'text-right', thClass: 'text-right',
        },
        {
          field: 'commision', label: 'Hoa hồng', sortable: false, tdClass: 'text-right', thClass: 'text-right',
        },
      ],
      bestProductItems: [
        {
          sku: '001002',
          product_name: 'Áo Sơ mi',
          category: 'Áo',
          total_order: 1200,
          total: 46000000,
          commission: 460000,
        },
        {
          sku: '001003',
          product_name: 'Áo polo',
          category: 'Áo',
          total_order: 1200,
          total: 46000000,
          commission: 460000,
        },
        {
          sku: '001004',
          product_name: 'Quần đùi',
          category: 'Quần',
          total_order: 1200,
          total: 46000000,
          commission: 460000,
        },
      ],
      configDateRangePickerTP: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        // defaultDate: [(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, -7)).toISOString(), (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString()],
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.topProductParams.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.topProductParams.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.loadTopProduct()
          }
        },
      },
      topProductOptionsRadio: [
        { text: '24h', value: 1 },
        { text: '7 Ngày', value: 7 },
        { text: '30 Ngày', value: 30 },
      ],
      topProductSelectedRadio: '',
      topProductParams: {
        defaultDate: null,
        endDate: null,
        startDate: null,
        fromToDate: null,
        page: 1,
        limit: 10,
        total: 100,
      },
      configDateRangePickerTS: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        // defaultDate: [(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, -7)).toISOString(), (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString()],
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.topSellerParams.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.topSellerParams.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.loadTopProduct()
          }
        },
      },
      topSellerSelectedRadio: '',
      topSellerParams: {
        defaultDate: null,
        endDate: null,
        startDate: null,
        fromToDate: null,
        page: 1,
        limit: 10,
        total: 100,
      },
      // setup params chart, dashboard, box
      boxData: {
        total_order: 0,
        order_cancel: 0,
        ctv: 0,
        profit: 0,
        revenue: 0,
      },
      configDateRangePickerChart: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        // defaultDate: [(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, -7)).toISOString(), (new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)).toISOString()],
        locale: Vietnamese,
        onChange: async ([start, end]) => {
          if (start && end) {
            this.topSellerParams.startDate = start.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.topSellerParams.endDate = end.toLocaleDateString('en-ZA').replaceAll('/', '-')
            this.loadTopProduct()
          }
        },
      },
      chartSelectedRadio: '',
      chartParams: {
        defaultDate: null,
        endDate: null,
        startDate: null,
        fromToDate: null,
      },
    }
  },
  created() {
    this.setDefaultDateRange()
  },
  methods: {
    setDefaultDateRange() {
      const d = new Date()
      const time = d.getTime()
      const d2 = new Date(time - 2629743000)
      this.topProductParams = {
        defaultDate: [d2.toISOString(), d.toISOString()],
        startDate: `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`,
        endDate: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
        fromToDate: `${d2.getDate()}-${d2.getMonth() + 1}-${d2.getFullYear()} đến ${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
      }
      this.topSellerParams = JSON.parse(JSON.stringify(this.topProductParams))
      this.chartParams = JSON.parse(JSON.stringify(this.topProductParams))
      this.loadDashboardBoxChart()
      this.loadDashboardChart()
      this.loadChartOrder()
      this.loadTopProduct()
      // this.loadTopSeller()
    },
    changeRadio(e, section) {
      const timestamp = e * 24 * 60 * 60 * 1000
      const d = new Date()
      const time = d.getTime()
      const d2 = new Date(time - timestamp)
      const setDataPicker = {
        defaultDate: [d2.toISOString(), d.toISOString()],
        startDate: `${d2.getFullYear()}-${d2.getMonth() + 1}-${d2.getDate()}`,
        endDate: `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`,
        fromToDate: `${d2.getDate()}-${d2.getMonth() + 1}-${d2.getFullYear()} đến ${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
      }
      if (section === 'TS') {
        this.topSellerParams = JSON.parse(JSON.stringify(setDataPicker))
        this.loadTopSeller()
      }
      if (section === 'TP') {
        this.topProductParams = JSON.parse(JSON.stringify(setDataPicker))
        this.loadTopProduct()
      }
      if (section === 'DB') {
        this.chartParams = JSON.parse(JSON.stringify(setDataPicker))
        this.loadDashboardBoxChart()
        this.loadDashboardChart()
      }
    },
    async loadDashboardBoxChart() {
      const params = {
        date_from: this.chartParams.startDate,
        date_to: this.chartParams.endDate,
        is_affiliate_customers: 1,
      }
      const res = await this.mixGet('/v2/dashboard/dashboard_box_operating_margin', params)
      if (res.status) {
        this.boxData = res.data
      }
    },
    async loadDashboardChart() {
      const params = {
        date_from: this.chartParams.startDate,
        date_to: this.chartParams.endDate,
      }
      const res = await this.mixGet('/v2/dashboard/dashboard_chart_operating_margin', params)
      if (res.status) {
        this.isShowChart = false
        this.lineChartData.labels = res.data.date
        this.lineChartData.datasets[0].data = res.data.total_order
        this.lineChartData.datasets[1].data = res.data.revenue
        this.lineChartData.datasets[2].data = res.data.commision
        this.lineChartData.datasets[3].data = res.data.profit
        this.isShowChart = true
      }
    },
    async loadChartOrder() {
      const params = {
        date_from: this.chartParams.startDate,
        date_to: this.chartParams.endDate,
      }
      const res = await this.mixGet('/v2/dashboard/dashboard_chart_total_order', params)
      if (res.status) {
        this.doughnutChartData.labels = res.data.name
        this.doughnutChartData.datasets[0].data = res.data.quantity
        this.doughnutChartData.total_order = res.data.total_order
      }
    },
    async loadTopProduct() {
      const params = {
        date_from: this.topProductParams.startDate,
        date_to: this.topProductParams.endDate,
      }
      const res = await this.mixGet('/v2/dashboard/dashboard_table_top_product', params)
      if (res.status) {
        this.bestProductItems = res.data
      }
    },
    async loadTopSeller() {
      const params = {
        date_from: this.topSellerParams.startDate,
        date_to: this.topSellerParams.endDate,
      }
      const res = await this.mixGet('/v2/dashboard/dashboard_table_top_seller', params)
      if (res.status) {
        this.bestSellerItems = res.data
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
